import { Button, Typography } from 'antd';

const ManagerView = ({ value, onEdit, disabledSave }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: value?.content,
        }}
      ></div>
      <div className="site-layout__content__button-div">
        <Button
          type="primary"
          className="site-layout__content__button-save"
          onClick={() => onEdit()}
          disabled={disabledSave}
        >
          Edit
        </Button>
      </div>
    </>
  );
};

export default ManagerView;
