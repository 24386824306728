import {
  Button,
  Col,
  Input,
  Layout,
  notification,
  Row,
  Typography,
} from 'antd';
import { useState } from 'react';
import {
  deleteRule,
  createRule,
  uploadRuleDoc,
  updateRule,
  detailRule,
} from '../api/service/ruleService';
import ManagerCreate from './content/managerCreate';
import ManagerEdit from './content/managerEdit';
import ManagerView from './content/managerView';
import fetchError from './fetchError';
import Information from './information';
import SpinLoading from './loading';
import { PopupCustomConfirmButton } from './PopupConfirm';
import { PopUpError } from './PopupError';

const { Content } = Layout;
const { Text } = Typography;
const LayoutContent = ({
  loading,
  setLoading,
  mode,
  title,
  setTitle,
  role,
  value,
  items,
  fetchListRules,
  fetchListRulesThenChange,
  setKeyItem,
  setMode,
  textEditor,
  setTextEditor,
  onEdit,
  onChangeItem,
  itemsFetch,
  documents,
  setDocuments,
}) => {
  const [importing, setImporting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusCheckTitle, setStatusCheckTitle] =
    useState(false);
  const [textCheckTitle, setTextCheckTitle] = useState('');

  const [statusCheckNote, setStatusCheckNote] =
    useState(false);
  const [textCheckNote, setTextCheckNote] = useState('');

  const [api, contextHolder] =
    notification.useNotification();
  const openNotification = (placement, data) => {
    api.info({
      message: data,
      placement,
    });
  };

  const handleChangeTitle = (e) => {
    const length = e.target.value.length;
    setTitle(e.target.value);
    if (length <= 0) {
      setStatusCheckTitle(true);
      setTextCheckTitle('The title is required.');
    } else if (length > 50) {
      setStatusCheckTitle(true);
      setTextCheckTitle(
        'The title cannot exceed 50 characters',
      );
    } else {
      setStatusCheckTitle(false);
      setTextCheckTitle('');
    }
  };
  const getLengthTextEditor = (length, html, text) => {
    if (length <= 0) {
      setStatusCheckNote(true);
      setTextCheckNote('The content is required.');
    } else if (length > 5000) {
      setStatusCheckNote(true);
      setTextCheckNote(
        'The content cannot exceed 5000 characters',
      );
    } else {
      setStatusCheckNote(false);
      setTextCheckNote('');
      setTextEditor(html);
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    const result = await deleteRule(value?.id);
    setDeleting(false);
    if (result?.data?.meta?.status === 200) {
      setKeyItem(0);
      setMode('view');
      openNotification('topRight', result?.data?.response);
      fetchListRulesThenChange();
    } else {
      PopUpError(fetchError(result));
    }
  };
  const onDelete = async () => {
    PopupCustomConfirmButton(
      'question',
      'Yes',
      'Are you sure want to delete ?',
      handleDelete,
    );
  };

  const onSaving = async () => {
    setSaving(true);

    const data = {
      title,
      content: textEditor,
      documents: documents.map((doc) => doc.path) ?? [],
    };

    const fetchCreate = async (data) => {
      return await createRule(data);
    };

    const fetchUpdate = async (data) => {
      return await updateRule(value.id, data);
    };
    if (mode === 'create') {
      fetchCreate(data).then((response) => {
        setSaving(false);
        if (response?.data?.meta?.status === 200) {
          openNotification(
            'topRight',
            response?.data?.response,
          );
          fetchListRulesThenChange();
          setKeyItem(0);
          setMode('view');
        } else {
          PopUpError(fetchError(response));
        }
      });
    } else {
      fetchUpdate(data).then((response) => {
        console.log('response', response);
        setSaving(false);
        if (response?.data?.meta?.status === 200) {
          openNotification(
            'topRight',
            response?.data?.response,
          );
          fetchListRulesThenChange();
          setKeyItem(0);
          setMode('view');
        } else {
          PopUpError(fetchError(response));
        }
      });
    }
  };

  const handleGetFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setImporting(true);
    const fileImport = e.target.files[0];
    if (fileImport) {
      uploadRuleDoc(fileImport).then((response) => {
        console.log('response doc', response);
        if (response?.data?.meta?.status === 200) {
          setImporting(false);
          setDocuments([
            ...documents,
            response?.data?.response,
          ]);
        } else {
          PopUpError(fetchError(response));
        }
      });
    }
  };

  const onBackToView = async () => {
    setLoading(true);
    await detailRule(value?.id).then((response) => {
      setLoading(false);
      if (response?.data?.meta?.status === 200) {
        fetchListRulesThenChange();
      } else {
        PopUpError(fetchError(response));
      }
    });
  };

  const disabledSave =
    textCheckNote || textCheckTitle || importing;

  return (
    <Content className="site-layout__content">
      {contextHolder}
      {loading ? (
        <SpinLoading />
      ) : (
        <Row
          align="space-around"
          wrap={true}
          gutter={[16, 16]}
        >
          <Col
            style={{ height: 'max-content' }}
            span={24}
            xl={mode === 'create' ? 24 : 18}
            lg={mode === 'create' ? 24 : 18}
            md={mode === 'create' ? 24 : 18}
            sm={24}
            xs={24}
          >
            <div className="container">
              {mode === 'edit' && (
                <div className="site-layout__content__button-top">
                  <Button
                    loading={deleting}
                    type="primary"
                    className="site-layout__content__button-delete"
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </div>
              )}
              {mode === 'view' ? (
                <div style={{ marginBottom: '16px' }}>
                  <Text className="site-layout__content__title">
                    {title}
                  </Text>
                </div>
              ) : (
                <>
                  <Input
                    className="site-layout__content__input"
                    placeholder="Title here"
                    size="large"
                    showCount={
                      mode === 'edit' || mode === 'create'
                    }
                    disabled={
                      mode !== 'edit' && mode !== 'create'
                    }
                    value={title}
                    onChange={handleChangeTitle}
                  />
                  {statusCheckTitle ? (
                    <Text type="danger">
                      {textCheckTitle}
                    </Text>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {role === 'manager' ? (
                mode === 'view' ? (
                  <ManagerView
                    value={value}
                    onEdit={onEdit}
                    disabledSave={disabledSave}
                  />
                ) : mode === 'edit' ? (
                  <ManagerEdit
                    textEditor={textEditor}
                    getLengthTextEditor={
                      getLengthTextEditor
                    }
                    textCheckNote={textCheckNote}
                    statusCheckNote={statusCheckNote}
                    deleting={deleting}
                    onDelete={onDelete}
                    saving={saving}
                    onSaving={onSaving}
                    disabledSave={disabledSave}
                    onBackToView={onBackToView}
                  />
                ) : (
                  <ManagerCreate
                    textEditor={textEditor}
                    getLengthTextEditor={
                      getLengthTextEditor
                    }
                    statusCheckNote={statusCheckNote}
                    textCheckNote={textCheckNote}
                    saving={saving}
                    onSaving={onSaving}
                    disabledSave={disabledSave}
                  />
                )
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value?.content,
                    }}
                  ></div>
                </>
              )}
            </div>
          </Col>

          <Col
            xl={mode === 'create' ? 24 : 6}
            lg={mode === 'create' ? 24 : 6}
            md={mode === 'create' ? 24 : 6}
            sm={24}
            xs={24}
          >
            <Information
              mode={mode}
              value={value}
              handleGetFile={handleGetFile}
              importing={importing}
              documents={documents}
            />
          </Col>
        </Row>
      )}
    </Content>
  );
};

export default LayoutContent;
