import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const ReactQuillStyled = styled(ReactQuill)`
  .ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-color: #e3e6ef;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: #e3e6ef;
    background-color: #fff;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  }
`;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
};

export const TextEditorTKG = (props) => {
  const propOverwrite = {
    // ...{ placeholder: '' },
    ...props,
  };
  const refEditor = useRef(null);

  const test = (e) => {
    props.getLengthTextEditor(
      refEditor.current?.unprivilegedEditor.getText()
        .length - 1,
      refEditor.current?.unprivilegedEditor.getHTML(),
      refEditor.current?.unprivilegedEditor.getText(),
    );
  };

  return (
    <ReactQuillStyled
      ref={refEditor}
      modules={modules}
      theme="snow"
      className={props.errorMessage ? 'error' : ''}
      onChange={test}
      defaultValue={props.value}
      // {...propOverwrite}
    />
  );
};
