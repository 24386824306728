import moment from 'moment';
import 'moment-timezone';

export function compareFnTimePopUp(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export const formatDateTimeByTimeZone = (date, format) => {
  return moment
    .utc(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format);
};

export const changeDateTimeZone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }
  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
};
