import React, { useReducer } from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import Context from './utils/context';
import * as ACTIONS from './store/actions/actions';
import * as TimerReducer from './store/reducers/timerReducer';
function App() {
  const [stateTimerReducer, dispatchTimerReducer] =
    useReducer(
      TimerReducer.TimerReducer,
      TimerReducer.initialState,
    );

  const handleShowModal = () => {
    dispatchTimerReducer(ACTIONS.showModal());
  };

  const handleHideModal = () => {
    dispatchTimerReducer(ACTIONS.hideModal());
  };

  return (
    <Context.Provider
      value={{
        isOpenModal: stateTimerReducer.isOpenModal,
        showModal: () => handleShowModal(),
        hideModal: () => handleHideModal(),
      }}
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
