import { Spin } from 'antd';

const SpinLoading = () => {
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Spin style={{ margin: 'auto' }} size="large"></Spin>
    </div>
  );
};

export default SpinLoading;
