import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import Verify from '../pages/verify-account';

export default function Router() {
  return useRoutes([
    {
      path: '/verify/:token',
      element: <Verify />,
    },
    {
      path: '/',
      element: <Dashboard />,
    },
  ]);
}
