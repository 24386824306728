import Swal from 'sweetalert2';

export const PopupCustomConfirmButton = (
  type,
  textButton,
  message,
  functionConfirm,
) => {
  Swal.fire({
    icon: type,
    text: message,
    focusConfirm: false,
    confirmButtonText: textButton,
    showCancelButton: true,
    cancelButtonText: 'No',
  }).then((result) => {
    if (result.isConfirmed) {
      functionConfirm();
    }
  });
};
