import { Typography, Button } from 'antd';
import { TextEditorTKG } from '../Texteditor';

const { Text, Title } = Typography;

const ManagerCreate = ({
  textEditor,
  getLengthTextEditor,
  statusCheckNote,
  textCheckNote,
  saving,
  onSaving,
  disabledSave,
}) => {
  return (
    <>
      <TextEditorTKG
        placeholder="Content here"
        value={textEditor}
        getLengthTextEditor={getLengthTextEditor}
      />
      {statusCheckNote ? (
        <Text type="danger">{textCheckNote}</Text>
      ) : (
        <></>
      )}

      <div className="site-layout__content__button-div">
        <Button
          loading={saving}
          type="primary"
          className="site-layout__content__button-save"
          onClick={onSaving}
          disabled={disabledSave}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ManagerCreate;
