const fetchError = (response) => {
  console.log(
    'rssdasdas',
    Object.keys(response?.data?.response)?.length,
  );
  if (Object.keys(response?.data?.response)?.length !== 0) {
    let reason = '';
    Object.keys(response?.data?.response).forEach(
      (element) => {
        reason += `\n${response?.data.response[element]}`;
      },
    );
    console.log('resson', reason);
    return reason;
  } else {
    console.log(
      'response?.data.meta?.message',
      response?.data?.meta?.message,
    );
    return response?.data?.meta?.message;
  }
};

export default fetchError;
