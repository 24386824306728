import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { verify } from '../../api/service/verifyService';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import './index.scss';

const Verify = () => {
  const navigate = useNavigate();
  const param = useParams();
  const token = param.token;
  const [loading, setLoading] = useState('true');
  const [failed, setFailed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  sessionStorage.setItem('accessToken', token);

  useEffect(() => {
    const fetchVerify = async () => {
      return await verify();
    };
    fetchVerify().then((response) => {
      setLoading(false);
      if (response.data.meta.status === 200) {
        if (searchParams.get('from-iframe') != null){
          sessionStorage.setItem('from-iframe', JSON.stringify(true));
        } else {
          sessionStorage.setItem('from-iframe', JSON.stringify(false));
        }
        sessionStorage.setItem(
          'user',
          JSON.stringify(response?.data?.response),
        );
        navigate('/');
      } else {
        setFailed(true);
      }
    });
  }, []);

  return (
    <div className="verify-background">
      {loading && <Spin style={{ margin: 'auto' }} />}
      {failed && (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography className="title-verify title-verify-failed">
            You have no permission!
          </Typography>
          <CloseCircleOutlined
            style={{
              fontSize: '120px',
              color: 'red',
              margin: 'auto',
            }}
          />
          <br />
        </div>
      )}
    </div>
  );
};

export default Verify;
