import { Input, Menu, Button, Badge, Popover } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const { Search } = Input;

const LayoutSider = ({
  handleSearch,
  keyItem,
  items,
  onChangeItem,
  handleCreate,
  onEdit,
  role,
  // fetchListRules,
}) => {
  const idUser = JSON.parse(
    sessionStorage.getItem('user'),
  )?.id;
  const itemsRef = useRef([]);
  // useEffect(() => fetchListRules(), []);
  useEffect(() => {
    itemsRef.current = itemsRef?.current.slice(
      0,
      items.length,
    );
  }, [items]);

  const checkTextOverFlow = (e) =>
    e?.offsetWidth < e?.scrollWidth;

  const checkNewRule = (item) =>
    !item?.viewers?.includes(idUser);

  return (
    <>
      <div className="sider__search-bar-menu">
        <Search
          placeholder="Search here"
          enterButton
          onChange={handleSearch}
        />
      </div>
      <div className={'sider__menu-side-bar'}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['0']}
          selectedKeys={[keyItem.toString()]}
        >
          {items.map((item, index) => (
            <Menu.Item
              key={index}
              onClick={(e) => onChangeItem(item?.id, index)}
            >
              {checkTextOverFlow(
                itemsRef.current[index],
              ) ? (
                <Popover
                  content={item?.title}
                  placement="topLeft"
                >
                  <div
                    ref={(el) =>
                      (itemsRef.current[index] = el)
                    }
                    className="sider__menu-side-bar__label"
                  >
                    {item.icon} {item?.title}
                  </div>
                </Popover>
              ) : (
                <div
                  ref={(el) =>
                    (itemsRef.current[index] = el)
                  }
                  className="sider__menu-side-bar__label"
                  // onClick={(e) =>
                  //   onChangeItem(item?.id, index)
                  // }
                >
                  {item.icon} {item?.title}
                </div>
              )}
              {checkNewRule(item) ? (
                <>
                  <div style={{ width: '5px' }}></div>
                  <span className="new-effect">New</span>
                </>
              ) : (
                <></>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </div>

      {role === 'manager' && (
        <div
          className="sider__button-create-rule"
          style={{
            margin: '10px',
          }}
        >
          <Button block onClick={handleCreate}>
            Create
          </Button>
        </div>
      )}
    </>
  );
};

export default LayoutSider;
