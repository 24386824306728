import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { TextEditorTKG } from '../Texteditor';

const { Text } = Typography;

const ManagerEdit = ({
  textEditor,
  getLengthTextEditor,
  textCheckNote,
  statusCheckNote,
  deleting,
  onDelete,
  saving,
  onSaving,
  disabledSave,
  onBackToView,
}) => {
  return (
    <>
      <TextEditorTKG
        placeholder="Content here"
        value={textEditor}
        getLengthTextEditor={getLengthTextEditor}
        errorMessage={textCheckNote}
      />
      {statusCheckNote ? (
        <Text type="danger">{textCheckNote}</Text>
      ) : (
        <></>
      )}

      <div style={{ display: 'flex' }}>
        <div className="site-layout__content__button-div">
          <Button
            // loading={deleting}
            type="primary"
            className="site-layout__content__button-back"
            onClick={onBackToView}
            icon={<ArrowLeftOutlined />}
          >
            Back To View
          </Button>
        </div>
        <div style={{ flex: 1 }}></div>
        <div className="site-layout__content__button-div">
          <Button
            loading={saving}
            type="primary"
            className="site-layout__content__button-save"
            onClick={onSaving}
            disabled={disabledSave}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ManagerEdit;
