import { getAuth } from '../authService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
  VERIFY: 'api/user/verify',
};

export const verify = async () => {
  return await getAuth(API_URL + endPoint.VERIFY);
};
