import React, {
  useCallback,
  useContext,
  useEffect, useRef,
  useState,
} from 'react';
import './index.scss';
import { encode } from 'js-base64';

import {
  Layout,
  Drawer,
  notification,
  Typography,
} from 'antd';
import {
  detailRule,
  listRules,
} from '../../api/service/ruleService';
import LayoutHeader from '../../components/header';
import LayoutSider from '../../components/sider';
import LayoutContent from '../../components/content';
import fetchError from '../../components/fetchError';
import { PopUpError } from '../../components/PopupError';
import { Timer } from '../../components/clockInClockOut/Timer';
import Context from '../../utils/context';
import Swal from 'sweetalert2';
const { Sider } = Layout;
const { Title } = Typography;

const bc = new BroadcastChannel('twt');


const formatTime = (timer, getHour = true) => {

  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return getHour ? `${getHours} : ${getMinutes} : ${getSeconds}` : `${getMinutes} : ${getSeconds}`
};

window.onload = function (){

  window.addEventListener(
    "message",
    function (event) {
      const { data, source } = event;
      bc.postMessage(data);
    },
    false
  );

}

function Dashboard() {

  const [loading, setLoading] = React.useState(false);
  const [api, contextHolder] =
    notification.useNotification();

  const [mode, setMode] = React.useState('view');
  const [keyItem, setKeyItem] = React.useState(0);

  const [itemsFetch, setItemsfetch] = React.useState([]);
  const [items, setItems] = React.useState(itemsFetch);
  const [value, setValue] = React.useState(items[0]);
  const [title, setTitle] = React.useState(value?.title);
  const [textEditor, setTextEditor] = React.useState(
    items[0]?.content,
  );
  const [documents, setDocuments] = React.useState(
    value?.documents ?? [],
  );

  const [visible, setVisible] = React.useState(false);
  const [dateState, setDateState] = useState(new Date());
  const iframe = document.getElementById('iframe-sso');
  const [timeLogout, setTimeLogout] = useState(0);

  const context = useContext(Context);

  bc.onmessage = useCallback((eventMessage) => {
    const data = eventMessage.data;
    if (data.type == 'refresh-token') {
      setTimeLogout(0);
      sessionStorage.setItem('accessToken', data.token);
    } else if (data.type == 'logout') {
      window.location.href = process.env.REACT_APP_DOMAIN_SSO
    } else if(data.type == 'show-timer-logout'){
      console.log(data);
      setTimeLogout(data.timer);
    }
  }, [bc]) ;
  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    if (!token) {
      window.location.replace(
        process.env.REACT_APP_DOMAIN_SSO
      );
    }
  }, []);

  useEffect(() => {
    if (timeLogout){
      let timerInterval;
      let localTimeLogout = timeLogout;
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Auto logout alert!',
        html: 'The system will automatically logout after  <br> <b style="font-size: 40px"></b>',
        timer: timeLogout * 1000,
        timerProgressBar: true,
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = formatTime(localTimeLogout, false);
            localTimeLogout -= 1;
          }, 1000)
        },
        willClose: () => {
          clearInterval(timerInterval)
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Refresh",
        cancelButtonText: "Close"

      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.isConfirmed) {
          iframe.contentWindow.postMessage({type: 'refresh-token-new'}, iframe.getAttribute('base-src'));
        }
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('123');
          iframe.contentWindow.postMessage({type: 'logout'}, iframe.getAttribute('base-src'));
        }
        setTimeLogout(0)
      })
    } else {
      Swal.close();
    }
  }, [timeLogout])

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  const openDrawer = () => setVisible(!visible);

  const fetchListRules = async () => {
    await listRules().then((response) => {
      if (response?.data?.meta?.status === 200) {
        setItemsfetch(response?.data?.response);
        setItems(response?.data?.response);
      } else {
        console.log('error fetch list');
      }
    });
  };

  const fetchListRulesThenChange = async () => {
    setLoading(true);
    await listRules().then((response) => {
      if (response?.data?.meta?.status === 200) {
        // setLoading(false);
        setItemsfetch(response?.data?.response);
        setItems(response?.data?.response);
        onChangeItem(response?.data?.response[0].id, 0);
        if (mode === 'edit') {
          onChangeItem(value?.id, keyItem);
        }
      } else {
        console.log('error fetch list');
      }
    });
  };

  const [role, setRole] = React.useState('employee');
  React.useEffect(() => {
    setRole(
      JSON.parse(sessionStorage.getItem('user'))
        ?.role_id === 1
        ? 'manager'
        : 'employee',
    );
    fetchListRulesThenChange();
  }, []);

  const onChangeItem = async (itemId, index) => {
    setLoading(true);
    await detailRule(itemId).then((response) => {
      setLoading(false);
      if (response?.data?.meta?.status === 200) {
        setMode('view');
        setKeyItem(index);
        setValue(response?.data?.response);
        setTextEditor(response?.data?.response?.content);
        setTitle(response?.data?.response?.title);
        setDocuments(response?.data?.response?.documents);
        fetchListRules();
      } else {
        // PopUpError(fetchError(response));
      }
    });
  };

  const onEdit = async (itemId, index) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setMode('edit');
  };

  const handleCreate = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
    setMode('create');
    setValue({ title: '', content: '', ...value });
    setTextEditor('');
    setDocuments([]);
    setTitle('');
  };

  const handleSearch = (e) => {
    const itemSearch = itemsFetch.filter((item) =>
      item?.title
        .toLocaleLowerCase()
        .includes(e.target.value.toLocaleLowerCase()),
    );
    setItems(itemSearch);
  };

  return (
    <>
      {contextHolder}
      <Layout>
        {(!JSON.parse(sessionStorage.getItem('from-iframe')) && sessionStorage.getItem('from-iframe') != null)  &&   <div style={{display: "none"}}>
          <iframe id="iframe-sso" base-src={`${process.env.REACT_APP_DOMAIN_SSO}`} src={`${process.env.REACT_APP_DOMAIN_SSO}verify/${encode(sessionStorage.getItem('accessToken'))}?from-iframe=true`}></iframe>
        </div>}
        <Drawer
          title={false}
          placement={'left'}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={'left'}
          width={270}
        >
          <Sider className="siderTablet" width={270}>
            <LayoutSider
              handleSearch={handleSearch}
              keyItem={keyItem}
              items={items}
              onChangeItem={onChangeItem}
              handleCreate={handleCreate}
              onEdit={onEdit}
              role={role}
              // fetchListRules={fetchListRules}
            />
          </Sider>
        </Drawer>
        <Sider className="sider" width={330}>
          <LayoutSider
            handleSearch={handleSearch}
            keyItem={keyItem}
            items={items}
            onChangeItem={onChangeItem}
            handleCreate={handleCreate}
            onEdit={onEdit}
            role={role}
            // fetchListRules={fetchListRules}
          />
        </Sider>
        <Layout className="site-layout">
          <LayoutHeader
            openDrawer={openDrawer}
            visible={visible}
          />

          <div
            style={{
              background: '#fff',
              boxShadow:
                '0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)',
              padding: 10,
            }}
          >
            <Title
              level={1}
              style={{
                margin: 0,
                // opacity: 0.7,
                height: 40,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span
                style={{
                  cursor: 'pointer',
                  margin: 'auto 0',
                  fontSize: '1.8rem',
                }}
                onClick={() => {
                  context.showModal();
                }}
              >
                {dateState.toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </span>
            </Title>
          </div>
          <Timer
            isOpenModal={context.isOpenModal}
            handleCancel={() => {
              context.hideModal();
            }}
          />

          {items.length === 0 && mode === 'view' ? (
            <></>
          ) : (
            <LayoutContent
              loading={loading}
              setLoading={setLoading}
              mode={mode}
              title={title}
              setTitle={setTitle}
              role={role}
              value={value}
              items={items}
              fetchListRules={fetchListRules}
              fetchListRulesThenChange={
                fetchListRulesThenChange
              }
              setKeyItem={setKeyItem}
              setMode={setMode}
              textEditor={textEditor}
              setTextEditor={setTextEditor}
              onEdit={onEdit}
              onChangeItem={onChangeItem}
              itemsFetch={itemsFetch}
              documents={documents}
              setDocuments={setDocuments}
            />
          )}
        </Layout>
      </Layout>
    </>
  );
}

export default Dashboard;
