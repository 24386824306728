import {
  getAuth,
  postAuth,
  putAuth,
  postFileAuth,
  deleteAuth,
} from '../authService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
  listRules: 'api/rule-fines',
  detailRule: 'api/rule-fines/detail/',
  createRule: 'api/rule-fines/store',
  uploadRuleDoc: 'api/rule-fines/upload-document',
  updateRule: 'api/rule-fines/update/',
  deleteRule: 'api/rule-fines/delete/',
};

export const listRules = async () => {
  return await getAuth(API_URL + endPoint.listRules);
};

export const detailRule = async (data) => {
  return await getAuth(
    API_URL + endPoint.detailRule + data,
  );
};

export const deleteRule = async (id) => {
  return await deleteAuth(
    API_URL + endPoint.deleteRule + id,
  );
};

export const createRule = async (data) => {
  return await postAuth(
    API_URL + endPoint.createRule,
    data,
  );
};
export const uploadRuleDoc = async (data) => {
  const file = new FormData();
  file.append('document', data);
  return await postFileAuth(
    API_URL + endPoint.uploadRuleDoc,
    file,
  );
};
export const updateRule = async (id, data) => {
  return await putAuth(
    API_URL + endPoint.updateRule + id,
    data,
  );
};
