import {
  CloseCircleFilled,
  CloseCircleOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import { useRef } from 'react';
import { PopupCustomConfirmButton } from './PopupConfirm';

const Information = ({
  mode,
  value,
  handleGetFile,
  importing,
  documents,
  setDocuments,
}) => {
  const inputFile = useRef(null);
  const handleClickImport = () => {
    inputFile.current.click();
  };

  const handleDeleteItem = (path) => {
    PopupCustomConfirmButton(
      'question',
      'OK',
      'Are you sure want to delete ?',
      () => {
        handleDeleteFile(path);
      },
    );
  };

  const handleDeleteFile = (path) => {
    setDocuments(
      documents.filter((item) => item.path !== path),
    );
  };
  return (
    <>
      {mode !== 'create' && (
        <div className="container site-layout__content__addition-block">
          <h3 className="site-layout__content__addition-block__label">
            Additional Information <br />
            <p className="site-layout__content__addition-block__label__footer">
              __ _
            </p>
          </h3>
          <p>
            <span className="site-layout__content__addition-block__title">
              Release Date:
            </span>
            {moment(value?.created_at).format(
              'DD/MM/YYYY hh:mm ',
            )}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Release Region:
            </span>{' '}
            {value?.release_region}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Authorised by:
            </span>{' '}
            {value?.created_by?.first_name}{' '}
            {value?.created_by?.surname}
          </p>
        </div>
      )}
      {mode !== 'create' && (
        <div className="container  site-layout__content__addition-block">
          <p>
            <span className="site-layout__content__addition-block__title">
              Updated Date:
            </span>{' '}
            {moment(value?.updated_at).format(
              'DD/MM/YYYY hh:mm ',
            )}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Updated By:
            </span>{' '}
            {value?.updated_by?.first_name}{' '}
            {value?.updated_by?.surname}
          </p>
        </div>
      )}
      <input
        type="file"
        id="file"
        accept=".jpg,.jpeg,.png,.gif,.xlsx,.docs,.pdf,.docx,.ppt,.pptx"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleGetFile}
      />

      <div className="container  site-layout__content__import-block ">
        {mode === 'edit' || mode === 'create' ? (
          <Button
            className="site-layout__content__import-block__button"
            onClick={handleClickImport}
            icon={<LinkOutlined />}
            loading={importing}
          >
            Upload document
          </Button>
        ) : (
          <></>
        )}
        {documents !== [] && (
          <div>
            {documents.map((doc, index) => (
              <div key={index} className="import-item">
                <a href={doc.preview} target="_blank">
                  {doc.path.slice(22)}
                </a>
                {mode !== 'view' && (
                  <div
                    className="import-item-del"
                    onClick={() => {
                      handleDeleteItem(doc.path);
                    }}
                  >
                    <CloseCircleOutlined />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Information;
